import "./new-test-run.scss";
import { ModalStore, ProjectStore, SettingsStore, TestRunStore, TriggerStore } from "stores";
import { Button, Checkbox, Icon, Modal, Popup } from "semantic-ui-react";
import TestRunEdit from "components/test-run-edit";
import TestRunForm from "models/forms/test-run-form";
import { observer } from "mobx-react-lite";
import { TriggerType } from "models/test-trigger";
import { showNotificationSettingsIfNeeded } from "components/modals/notification-settings";
import { createRef, useEffect, useState } from "react";
import Project from "../../../models/project";

const testRunForm = new TestRunForm();

const autoSelect = createRef<HTMLElement>();

const NewTestRun = observer(() => {
  const { newTestRun } = ModalStore;
  const [project, setProject] = useState<Project>(null);

  ModalStore.newTestRun.onShown = () => {
    testRunForm.clearFormData();
    updateProject();
    if (autoSelect.current)
      autoSelect.current.focus();
  };

  ModalStore.newTestRun.onClose = () => {
    setProject(null);
  };

  useEffect(() => {
    updateProject();
  }, [testRunForm.projectId]);

  function updateProject() {
    setProject(ProjectStore.find(testRunForm.projectId));
  }

  const runTest = async () => {
    if (testRunForm.triggerType == TriggerType.now) {
      const environment = SettingsStore.environments.find((e) => e.id == testRunForm.environmentId);

      testRunForm.submit(async (data) => {
        const testRun = await TestRunStore.createTestRun({
          project: project.key,
          buildId: data.buildName,
          branch: data.branch,
          environment: environment.key,
          ref: data.commit == "latest" ? null : data.commit,
          includeTags: data.includeTags,
          excludedTags: data.excludedTags,
          testFilters: data.testFilters,
          runners: data.maxRunners,
          parallelizeByFile: data.defaultToParallelizeByFile,
          testTimeoutSeconds: data.testTimeoutSeconds,
          timeoutMinutes: data.timeoutMinutes,
          latestDeploy: data.latestDeploy,
          priority: data.priority,
          retryFailedTests: data.retryFailedTests,
          testSuiteId: data.testSuiteId,
          playwrightProject: data.playwrightProject,
          ignoreQuarantine: data.ignoreQuarantine,
          applyTestSelectionRules: data.testOptionsType == "codeConfig"
        });
        newTestRun.hide();

        const doToggle = () => TestRunStore.toggleFollow(testRun.id);

        if (testRunForm.followTestRun) {
          doToggle();
          showNotificationSettingsIfNeeded(doToggle);
        }
      }, true);
    } else {
      await TriggerStore.save(testRunForm);
      newTestRun.hide();
    }
  };

  return (
    <Modal size="small" open={newTestRun.showing} id="new-test-run-modal">
      <Modal.Header>New Test Run</Modal.Header>
      <Modal.Content>
        <TestRunEdit form={testRunForm} projectElement={autoSelect} />
        <br />
        <Checkbox
          label="Follow Test Run"
          checked={testRunForm.followTestRun}
          toggle
          onChange={() => testRunForm.updateField("followTestRun", !testRunForm.followTestRun)}
        />
        <div className="follow-message">
          Receive slack or email notifications when a test run completes and when messages are
          added.
        </div>
      </Modal.Content>
      <Modal.Actions>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div>
            <>
              <Checkbox label={"Include Quarantined Tests in Results"}
                        onChange={() => testRunForm.updateField("ignoreQuarantine", !testRunForm.ignoreQuarantine)}
                        checked={testRunForm.ignoreQuarantine}
                        toggle={true}
                        style={{ marginRight: "5px" }}
              />
              <Popup
                hoverable={true}
                content={
                  <span>
                      Enabling this setting will ignore the quarantine settings for all tests in this test run. When enabled, no tests will be quarantined.
                  </span>
                }
                trigger={<span style={{ fontSize: ".8em", textDecoration: "underline", color: "#1a0dab" }}>What's this?</span>}
              />
            </>
          </div>
          <div>
            <Button disabled={testRunForm.isSubmitting} onClick={() => newTestRun.hide()}>
              <Icon name="times" />
              Cancel
            </Button>
            <Button loading={testRunForm.isSubmitting} color="green" onClick={runTest}>
              <Icon name="check" />
              {testRunForm.triggerType == TriggerType.now ? "Run Test" : "Create Schedule"}
            </Button>
          </div>
        </div>
      </Modal.Actions>
    </Modal>
  );
});

export default NewTestRun;
