import Form from "./form";
import { action, observable, reaction } from "mobx";
import { EnvironmentVar } from "models/settings";
import { notEmpty } from "core/validation";
import { AppStore, ProjectStore, SettingsStore, TestSuiteStore } from "stores";
import parseCron from "cron-validate";
import TestTrigger, { TriggerType } from "models/test-trigger";

export default class TestRunForm extends Form {
  @observable id: number;
  @observable projectId: number;
  @observable testSuiteId: number;
  @observable environmentId: number;
  @observable pipelineStageId: number;
  @observable testPlanId: number;
  @observable deployedProjectIds: number[] = [];
  @observable buildName: string;
  @observable maxRunners: number;
  @observable maxRunnersAllowed: number;
  @observable copies: number;
  @observable commit: string;
  @observable includeTags: string[];
  @observable excludedTags: string[];
  @observable testFilters: string[];
  @observable variables: EnvironmentVar[];
  @observable testName: string;
  @observable branch: string;
  @observable defaultToParallelizeByFile: boolean;
  @observable testTimeoutSeconds: number;
  @observable timeoutMinutes: number;
  @observable triggerType: TriggerType;
  @observable latestDeploy: boolean;
  @observable onDeploy: boolean;
  @observable cron: string;
  @observable name: string;
  @observable priority: number;
  @observable retryFailedTests: boolean;
  @observable followTestRun: boolean;
  @observable testOptionsType: "all" | "suite" | "specify" | "codeConfig";
  @observable playwrightProject: string;
  @observable ignoreQuarantine: boolean;
  scheduleOnly = false;

  validators = {
    projectId: notEmpty,
    environmentId: {
      validator: (environmentId: number) => {
        return this.scheduleOnly || environmentId != null;
      },
      message: "You must select an environment",
    },
    buildName: {
      validator: (buildName: string) => {
        const project = ProjectStore.find(this.projectId);
        if (this.triggerType === TriggerType.testPlanInterval || this.triggerType === TriggerType.testPlanDeploy)
          return true;

        return this.latestDeploy || !project || !project.hasBuildArtifacts || notEmpty(buildName);
      },
      message: "You must select a build",
    },
    cron: {
      validator: (cron: string) =>
        (this.triggerType != TriggerType.interval && this.triggerType != TriggerType.testPlanInterval) ||
        (cron && parseCron(cron).isValid()),
      message: "Valid Cron expression required",
    },
    testPlanId: {
      validator: (testPlanId: number) => !!testPlanId || this.triggerType != TriggerType.testPlanInterval,
      message: "You must select a Test Plan",
    },
    name: {
      validator: (name: string) => this.triggerType == TriggerType.now || notEmpty(name),
      message: "You must specify a name",
    },
    deployedProjectIds: {
      validator: (values: number[]) => !this.onDeploy || values.length > 0,
      message: "You must select at least one project",
    },
    latestDeploy: {
      validator: (latestDeploy) => !latestDeploy || this.triggerType != TriggerType.now || this.projectHasDeploy(),
      message: "Selected project does not have a latest deploy",
    },
  };

  constructor(data?: TestTrigger) {
    super();

    this.updateValidators();

    if (data) {
      this.environmentId = null;
      this.pipelineStageId = null;
      this.populate(data);
      this.buildName = data.build?.name;
      if (data.testPlanId) {
        this.triggerType = data.onDeploy ? TriggerType.testPlanDeploy : TriggerType.testPlanInterval;
      } else {
        this.triggerType = data.onDeploy ? TriggerType.deploy : TriggerType.interval;
      }
      this.resetMaxRunnersAllowed();
      if (data.testSuiteId) {
        this.testOptionsType = "suite";
      } else if (data.hasFilters) {
        this.testOptionsType = "specify";
      } else {
        this.testOptionsType = "all";
      }
    }

    reaction(
      () => this.projectId,
      (projectId) => {
        this.clearErrors();
        this.branch = "master";
        this.commit = null;
        this.buildName = null;
        this.includeTags = [];
        this.excludedTags = [];
        this.testFilters = [];
        this.defaultToParallelizeByFile = false;
        this.timeoutMinutes = null;
        this.testTimeoutSeconds = null;
        this.latestDeploy = false;
        this.ignoreQuarantine = false;

        const project = ProjectStore.find(projectId);
        if (project) {
          this.latestDeploy = ProjectStore.hasDeploy(projectId, this.environmentId);
          this.defaultToParallelizeByFile = project.defaultToParallelizeByFile;
          this.branch = project.defaultBranch;
          this.timeoutMinutes = project.testRunTimeoutMinutes;
          this.testTimeoutSeconds = project.testTimeoutSeconds;
          const count = AppStore.account.maxRunners(project);
          this.maxRunners = project.defaultMaxRunners || count;
          this.maxRunnersAllowed = count;
          this.retryFailedTests = project.retryFailedTests;
          this.testOptionsType = TestSuiteStore.projectHasActiveTestSuites(project.id) ? "suite" : "all";
        }
      },
    );

    reaction(
      () => this.environmentId,
      () => {
        this.latestDeploy = ProjectStore.hasDeploy(this.projectId, this.environmentId);
        const environment = SettingsStore.findEnvironment(this.environmentId);
        this.priority = environment?.pipelineStage?.testRunPriority || this.priority;
      },
    );

    reaction(
      () => this.branch,
      () => {
        this.commit = null;
      },
    );

    reaction(
      () => this.testOptionsType,
      () => {
        this.updateField("testSuiteId", null);
        this.updateValidators();
      },
    );

    reaction(
      () => this.testSuiteId,
      () => {
        const testSuite = TestSuiteStore.find(this.testSuiteId);
        const project = ProjectStore.find(this.projectId);
        const count = AppStore.account.maxRunners(project);

        if (testSuite) {
          this.maxRunners = null;
        } else {
          this.maxRunners = project.defaultMaxRunners || count;
        }
      },
    );

    reaction(
      () => this.triggerType,
      (value) => {
        this.cron = null;
        this.onDeploy = value == TriggerType.deploy || value == TriggerType.testPlanDeploy;
        if (this.onDeploy) {
          this.deployedProjectIds = [this.projectId];
        } else {
          this.deployedProjectIds = [];
        }
        if (!this.isTestPlanTrigger) {
          this.testPlanId = null;
        }
        this.updateValidators();
      },
    );

    reaction(
      () => this.latestDeploy,
      () => {
        this.commit = null;
      },
    );
  }

  updateValidators() {
    if (this.testOptionsType === "suite" && this.triggerType !== TriggerType.testPlanDeploy && this.triggerType !== TriggerType.testPlanInterval)
      this.validators["testSuiteId"] =
        {
          validator: (testSuiteId) => testSuiteId || this.testOptionsType != "suite",
          message: "You must select a Test Suite",
        };
    else
      delete this.validators["testSuiteId"];
  }

  resetMaxRunnersAllowed() {
    const project = ProjectStore.find(this.projectId);

    if (project && AppStore.account) {
      this.maxRunnersAllowed = AppStore.account.maxRunners(project);
    } else {
      this.maxRunnersAllowed = 10;
    }
  }

  @action
  clearForSchedule() {
    this.clearFormData();
    this.environmentId = null;
    this.scheduleOnly = true;
  }

  @action
  setDefaults() {
    this.triggerType = TriggerType.now;
    this.branch = "master";
    this.excludedTags = [];
    this.testFilters = [];
    this.variables = [];
    this.includeTags = [];
    this.deployedProjectIds = [];
    this.copies = 1;
    this.latestDeploy = ProjectStore.hasDeploy(this.projectId, this.environmentId);
    this.onDeploy = false;
    this.retryFailedTests = false;
    this.followTestRun = true;
    this.testOptionsType = "all";
    this.playwrightProject = null;

    const project = ProjectStore.active[0];

    if (project) {
      this.projectId = project && project.id;
      this.testTimeoutSeconds = project.testTimeoutSeconds;
      this.timeoutMinutes = project.testRunTimeoutMinutes;
      this.defaultToParallelizeByFile = project.defaultToParallelizeByFile;
      this.retryFailedTests = project.retryFailedTests;
      this.testOptionsType = TestSuiteStore.projectHasActiveTestSuites(project.id) ? "suite" : "all";
    }

    const env = SettingsStore.activeEnvironments[0];
    this.environmentId = env && env.id;
    this.priority = env ? env.pipelineStage.testRunPriority : 1;

    this.resetMaxRunnersAllowed();
    this.maxRunners = project?.defaultMaxRunners || this.maxRunnersAllowed;
  }

  projectHasDeploy() {
    return ProjectStore.hasDeploy(this.projectId, this.environmentId);
  }

  get isRunNow() {
    return this.triggerType == TriggerType.now;
  }

  get isTestPlanTrigger() {
    return this.triggerType == TriggerType.testPlanInterval || this.triggerType == TriggerType.testPlanDeploy;
  }
}
