import Page from "pages/page";
import { Tab, TabProps } from "semantic-ui-react";
import IntegrationsTab from "./integrations-tab";
import RunnerConfigurationsTab from "./runner-configurations-tab";
import TestDistributionSettingsTab from "./test-distribution-settings-tab";
import "./settings.scss";
import { Icons } from "core/constants";
import PaymentTab from "./payment-tab";
import { useHistory, useParams } from "react-router";
import { findIndex } from "lodash";
import { AppStore } from "stores";
import UsersTab from "./users-tab";
import TeamsTab from "./teams-tab";
import { useEffect, useState } from "react";
import { useInitialEffect } from "../../core/react-utils";
import { Plan } from "../../models/plan";
import Spinner from "../../components/spinner";

const SettingsPage = () => {
  const [availablePlans, setAvailablePlans] = useState<Array<Plan>>(null)

  useInitialEffect(async () => {
    const plans = await AppStore.loadAvailablePlans();
    setAvailablePlans(plans);
  });

  const panes = [
    {
      menuItem: { key: "users", icon: "user", content: "Users" },
      render: () => <UsersTab />,
    },
    {
      menuItem: { key: "teams", icon: "users", content: "Teams" },
      render: () => <TeamsTab />,
    },
    {
      menuItem: { key: "integrations", icon: "exchange", content: "Integrations" },
      render: () => <IntegrationsTab />,
    },
    {
      menuItem: { key: "payment", icon: "payment", content: "Payment" },
      render: () => <PaymentTab />,
    },
  ];
  panes.push({
    menuItem: { key: "runner-configurations", icon: "terminal", content: "Test Stacks" },
    render: () => <RunnerConfigurationsTab />,
  });
  panes.push({
    menuItem: { key: "test-distribution-settings", icon: "cogs", content: "Test Distribution" },
    render: () => <TestDistributionSettingsTab />,
  });
  const history = useHistory();
  const { tab } = useParams<{ tab: string }>();

  useEffect(() => {
    if (!tab) {
      history.replace("settings/users");
    }
  }, [tab]);

  const navigate = (data: TabProps) => {
    history.push(panes[data.activeIndex].menuItem.key);
  };

  const userPanes = AppStore.userIsAdmin ? panes : [panes[0], panes[1]];

  if (!availablePlans)
    return <Spinner/>

  return (
    <Page name="settings" title="Settings" icon={Icons.settings}>
      <Tab
        onTabChange={(_, data) => navigate(data)}
        activeIndex={findIndex(userPanes, (i) => i.menuItem.key == tab)}
        menu={{ secondary: true, pointing: true }}
        panes={userPanes}
      />
    </Page>
  );
};

export default SettingsPage;
