import Page from "pages/page";
import { Tab, TabProps } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { useHistory, useParams } from "react-router";
import { useInitialEffect } from "core/react-utils";
import { findIndex } from "lodash";
import TestRunsTab from "./test-runs-tab";
import TestsTab from "./tests-tab";
import "./analytics.scss";
import FlakyTestsTab from "./flaky-tests-tab";
import TestRunComparisonTab from "./test-run-comparison-tab";
import TestDistributionTab from "./test-distribution-tab";
import UsageTab from "./usage-tab";

const AnalyticsPage = observer(() => {
  const { tab } = useParams<{ tab: string }>();
  const history = useHistory();

  useInitialEffect(() => {
    if (!tab) {
      history.replace("analytics/test-runs");
    }
  });

  const tabPanes = [
    {
      menuItem: { key: "test-runs", icon: "chart bar outline", content: "Test Run Status" },
      render: () => <TestRunsTab />,
    },
    {
      menuItem: { key: "tests", icon: "chart bar outline", content: "Test Status" },
      render: () => <TestsTab />,
    },
    {
      menuItem: { key: "flaky-tests", icon: "chart bar outline", content: "Flaky Tests" },
      render: () => <FlakyTestsTab />,
    },
  ];
  tabPanes.push({
    menuItem: { key: "test-run-comparison", icon: "chart line", content: "Test Run Comparison" },
    render: () => <TestRunComparisonTab />,
  })
  tabPanes.push({
    menuItem: { key: "test-distribution", icon: "chart bar outline", content: "Test  Distribution" },
    render: () => <TestDistributionTab />,
  });
  tabPanes.push({
    menuItem: { key: "usage", icon: "chart line", content: "Usage"},
    render: () => <UsageTab />
  })

  const tabIndex = findIndex(tabPanes, (i) => i.menuItem.key == tab);

  const navigate = (data: TabProps) => {
    const name = tabPanes[data.activeIndex].menuItem.key;
    history.push(name);
  };

  return (
    <Page name="analytics" title="Analytics">
      <Tab
        onTabChange={(_, data) => navigate(data)}
        activeIndex={tabIndex}
        menu={{ secondary: true, pointing: true }}
        panes={tabPanes}
      />
    </Page>
  );
});

export default AnalyticsPage;
